/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;



@import "assets/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";

@import "utilities/variables.scss";

@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@300;400;500;600;700&family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');

$primary-color: rgb(22 160 173 );

button.p-button {
  background-color: $primary-color;
}

html, body{
    font-family: 'Raleway', sans-serif;
    margin: 0;
    padding: 0;
    color: #002A4A;
}

h1, h2, h3, h4, h5, h6, p{
    margin: 0;
    padding: 0;
}

.loader {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 32px 0 #fff, -32px 0 #fff;
  position: relative;
  animation: flash 0.5s ease-out infinite alternate;
  display: inline-block;
}

@keyframes flash {
  0% {
    background-color: #FFF2;
    box-shadow: 32px 0 #FFF2, -32px 0 #FFF;
  }
  50% {
    background-color: #FFF;
    box-shadow: 32px 0 #FFF2, -32px 0 #FFF2;
  }
  100% {
    background-color: #FFF2;
    box-shadow: 32px 0 #FFF, -32px 0 #FFF2;
  }
}

.p-component{
  font-family: 'Figtree', sans-serif !important;

}

.p-datatable {

  .p-datatable-thead > tr > th{
    background-color: white;
    color: $primary-color;
    text-align: left;
    padding-left: 2rem;
    font-weight: 500;
    font-size: 14px;

    &.p-highlight {
      background-color: white;
      font-weight: 800;
      color: $primary-color;
    }
  }

  .p-datatable-tbody {
    tr{
      td {
        padding: 0.5rem 0.5rem;

        &:first-child {
          padding-left: 1rem;
          border-left: 1px solid #dee2e6;
        }
        &:last-child {
          border-top: 1px solid #dee2e6;
          border-right: 1px solid #dee2e6;
        }
      }
    }
  }
  .p-sortable-column{

    &:focus{
      box-shadow: none;
    }
    &:not(.p-highlight):hover{
      color: $primary-color;
      background-color: #e9ecef;
    }
    &.p-highlight{

      &:hover{
        color: $primary-color;
        background-color: #e9ecef;
        .p-sortable-column-icon{
          color: $primary-color;
        }
      }
      .p-sortable-column-icon{
        color: $primary-color;
      }
    }
  }
  .p-component-overlay{
    background-color: rgba(255, 255, 255, 0.8);
  }
  spinnericon svg{
    width: 30px;
    height: 30px;
  }
}
.p-paginator {

  border: none;
  padding-left: 0;
  padding-right: 0;
  .p-paginator-element{
    border-radius: 0;
    margin: 0;
    min-width: 2rem;

  }
  .p-paginator-pages .p-paginator-page{
    border-radius: 0;
    margin: 0;
    min-width: 2rem;
    font-family: 'Figtree', sans-serif;
    &.p-highlight{
      background-color: white;
      color: $primary-color;
      font-weight: 800;
      border-bottom: 3px solid $primary-color;
      box-shadow: none !important;
    }
  }
  .p-paginator-current {
    font-family: 'Figtree', sans-serif;
    margin-right: auto;
  }

}



/*
.p-button{
  font-size:15px;
  padding: 0.5rem 1rem;

}
*/

.p-dialog-header{
  background-color: #D0ECEF !important;
  color: #000 !important;
}
.p-dialog-content{
  padding-top: 30px  !important;
}
.p-button-danger{
  background-color: #FF025D !important;
  border-color: #FF025D !important;
}
.p-button-danger.p-button-outlined{
  border-color: #FF025D !important;
  color: #FF025D  !important;
}


.p-input-underline{

  .p-inputtext, .p-dropdown{
    border: none;
    border-bottom: 1px solid #ced4da;
    border-radius: 0;
    padding-left: 0;
    box-shadow: none !important;
    outline: none;
    color: #333333;
    font-size: 14px;
    font-weight: 600;
  }
  .p-inputtext::placeholder, .p-placeholder{
      color: #99a6b1 !important;
      font-weight: 400;
  }
  label{
      color: #667F92;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0.36px;
  }
  .p-dropdown-label{
    border: none;
  }
  .p-dropdown-filter-container input{
    border: none !important;
    padding-left: 1rem !important;

  }
}
.p-autocomplete-item.p-highlight{
  background: #fff !important;
}
.p-autocomplete-item:hover{
  background: #f0f0f0 !important;
}

.p-toast {
  margin-bottom: 80px;
}

.p-buttonset .p-button:first-of-type {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.p-buttonset .p-button:not(:last-child) {
  border-right: 0 none;
}
.p-buttonset .p-button:last-of-type {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}


p-chips.stacked-readonly{
  display: block;
  margin-top: 0.5rem;
  ul{
      padding: 0 !important;
      border: none !important;
      font-size: 14px !important;
      flex-direction: column;
      align-items: flex-start;
      gap: 1px !important;
      border-radius: 0 !important;

      li{
          min-width: 250px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-radius: 0 !important;
      }

  }
  .p-chips-input-token{
      display: none !important;
  }
}

p-button button{
  /*min-width: 180px;*/
  display: flex;
  justify-content: center;
  .p-button-label{
    flex: none;
  }
}

.p-dialog-content{
  display: block !important;
}

.overflow_hidden {
  overflow: hidden;
}

.p-error-text {
  color: $utility-error;
}

.p-trash-button {
  height: 3rem;
  width: 3rem;;
  display: block;
  align-self: center;
  background-color: $utility-error;
  border-radius: 50%;
  border-color: transparent;
  color: white;
  cursor: pointer;
}
