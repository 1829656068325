/*
*
Colors
*
*/

/*
Primary
*/
$primary-turquoise-100: #16A0AD;
$primary-navy-100: #002A4A;
$primary-mint-100: #16D0CC;

/*
Secondary
*/
$secondary-turquoise-60: #73C6CE;
$secondary-turquoise-30: #D0ECEF;
$secondary-turquoise-10: #FFF;
$secondary-navy-80: #33556E;
$secondary-navy-60: #667F92;
$secondary-navy-30: #B3BFC9;
$secondary-navy-20: #CCD4DB;
$secondary-navy-10: #E5EAED;
$secondary-navy-5: #F2F4F6;

/*
Utility
*/
$utility-error: #FF025D;
$utility-sucess: #FFF;

/*
Grey
*/
$grey-white: #FFF;
